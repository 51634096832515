import React from 'react'

import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

const ScrollMagic = ({
  children,
  effects,
  duration,
  debugMode,
  triggerHook,
}) => (
  <Controller>
    <Scene
      triggerHook={triggerHook}
      duration={duration}
      pin
      indicators={debugMode}
    >
      {progress => (
        <div>
          <Timeline totalProgress={progress} paused target={children}>
            {effects.map((e, i) => (
              <Tween key={i} from={e.from} to={e.to} />
            ))}
          </Timeline>
        </div>
      )}
    </Scene>
  </Controller>
)

ScrollMagic.defaultProps = {
  triggerHook: 0.1,
  duration: 8000,
  debugMode: false,
}

export default ScrollMagic
