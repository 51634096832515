import React from 'react'
import { PropTypes } from 'prop-types'
//TODO fix post links
import * as classNames from 'classnames/bind'

import s from './post.module.scss'
import GradientTitle from './gradient-title'
import PostSubTitle from './post-sub-title'
import PostText from './post-text'

const Post = ({ title, subTitle, text, imgSrc, link }) => {
  const renderTitle = () => (
    <>
      {link ? (
        <a className={s.link} href={link} target="_blank">
          <GradientTitle text={title} />
        </a>
      ) : (
        <GradientTitle text={title} />
      )}
    </>
  )

  return (
    <div className={s.container}>
      <div style={imgSrc ? { flex: 0.7 } : { flex: 1 }}>
        {renderTitle()}
        <PostSubTitle text={subTitle} />
        <PostText text={text} />
      </div>
      {imgSrc && (
        <div className={s.imageContainer}>
          <img className={s.image} src={imgSrc} alt="" />
        </div>
      )}
    </div>
  )
}

export default Post

Post.prototypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
}
