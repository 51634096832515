import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Intro from '../components/intro'
import AboutText from '../components/about-text'
import Hr from '../components/hr'
import SectionTitle from '../components/section-title'
import passionfruit from '../images/passionfruit.png'
import MagicProfileImage from '../components/magic-profile-image'
import s from './index.module.scss'
import Posts from '../components/Posts'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header />
    <Intro />
    <MagicProfileImage />
    <AboutText
      text={`I am a Fullstack Developer with a strong focus on React/Typescript for frontend development and Java Spring Boot for backend development. Additionally, I have experience in AWS, Kubernetes, and setting up CI/CD workflows. While these technologies are my strengths, I'm passionate about learning and using new tools and technologies.`}
    />
    <Hr classes={[s.hrStyle]} />

    <SectionTitle text={`Experience`} classes={[s.secTitleStyle]} />
    <Posts postType={'experience'} />

    <Hr classes={[s.hrStyle]} />

    <div className={s.passionProjects}>
      <img className={s.passionfruit} src={passionfruit} alt="" />
      <SectionTitle text={`Projects`} classes={[s.secTitleStyle]} />
    </div>
    <Posts postType={'passion-project'} />
  </Layout>
)

export default IndexPage
