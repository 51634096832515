import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Post from './post'
import { PropTypes } from 'prop-types'

const Posts = ({ data, postType }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      {posts.length
        ? posts
            .filter(
              ({
                node: {
                  frontmatter: { type },
                },
              }) => postType === type
            )
            .map(
              (
                {
                  node: {
                    frontmatter: { company, title, image, link },
                    internal: { content },
                  },
                },
                i
              ) => (
                <Post
                  key={i}
                  title={company}
                  subTitle={title}
                  text={content}
                  imgSrc={image || null}
                  link={link}
                />
              )
            )
        : null}
    </>
  )
}

Posts.propTypes = {
  data: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query GetPosts {
        allMarkdownRemark {
          edges {
            node {
              internal {
                content
              }
              frontmatter {
                company
                path
                title
                image
                type
                link
              }
            }
          }
        }
      }
    `}
    render={data => <Posts data={data} {...props} />}
  />
)
