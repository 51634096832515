import React from 'react'
import PropTypes from 'prop-types'

import s from './layout.module.scss'
import './layout.css'

const Layout = ({ children }) => (
  <>
    <div className={s.container}>
      {children}
      <footer className={s.footerStyle}>
        © {new Date().getFullYear()}, Daniel Ghandahari
      </footer>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
