import React from 'react'
import * as classNames from 'classnames/bind'

import s from './section-title.module.scss'
import { PropTypes } from 'prop-types'

let cx = classNames.bind(s)

const SectionTitle = ({ text, classes }) => (
  <div className={cx({ container: true }, classes)}>{text}</div>
)

export default SectionTitle

SectionTitle.prototypes = {
  text: PropTypes.string,
}
