import React from 'react'
import * as classNames from 'classnames/bind'

import hr from '../images/Hr.png'
import s from './hr.module.scss'

let cx = classNames.bind(s)

const Hr = ({ classes }) => (
  <div className={cx({ container: true }, classes)}>
    <img src={hr} alt="" />
  </div>
)

export default Hr
