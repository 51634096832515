import React from 'react'
import { PropTypes } from 'prop-types';

import s from './post-sub-title.module.scss'

const PostSubTitle = ({ text }) => (
	<div className={s.container}>
		{text}
	</div>
)

export default PostSubTitle

PostSubTitle.prototypes = {
	text: PropTypes.string
}