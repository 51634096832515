import React from 'react'

import s from './intro.module.scss'
import SectionTitle from './section-title'
import SubSectionTitle from './subsection-title'
import Hr from './hr'
import ProfileImage from './images/ProfileImage'

class Intro extends React.Component {
  render() {
    return (
      <div className={s.container}>
        <div className={s.section}>
          <SectionTitle text={`Hi, I’m Daniel Ghandahari`} />
          <Hr />
          <SubSectionTitle
            text={`Passionated fullstack developer 🤙`}
          />
        </div>
        <div className={s.imageContainer} style={{ width: '100%' }}>
          <ProfileImage className={s.image} />
        </div>
      </div>
    )
  }
}

export default Intro
