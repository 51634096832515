import React from 'react'
import { PropTypes } from 'prop-types';

import s from './post-text.module.scss';

const PostText = ({ text }) => (
	<div className={s.container}>
		{text}
	</div>
)

export default PostText

PostText.prototypes = {
	text: PropTypes.string
}