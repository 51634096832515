import React from 'react'

import s from './magic-profile-image.module.scss'
import ScrollMagic from '../components/scroll-magic'
import profile from '../images/profile.jpg'

const scrollEffects = [
  {
    from: {
      x: '-200%',
      opacity: 0,
    },
    to: {
      x: '-120%',
      opacity: 1,
    },
  },
]

const MagicProfileImage = () => (
  <ScrollMagic effects={scrollEffects} duration={1000} triggerHook={0}>
    <div className={s.container}>
      <img src={profile} className={s.image} alt="" />
    </div>
  </ScrollMagic>
)

export default MagicProfileImage
