import React from 'react'
import { PropTypes } from 'prop-types'

import s from './gradient-title.module.scss'

const GradientTitle = ({ text }) => <div className={s.container}>{text}</div>

export default GradientTitle

GradientTitle.prototypes = {
  text: PropTypes.string,
}
