import React from 'react';

import s from './about-text.module.scss';
import { PropTypes } from 'prop-types';

const AboutText = ({ text }) => (
	<div className={s.container}>
		{text}
	</div>
)

export default AboutText

AboutText.prototypes = {
	text: PropTypes.string,
}