import { StaticQuery } from 'gatsby'
import React from 'react'
import { graphql } from 'gatsby'

import s from './header.module.scss'

const smLinks = [
  'https://www.facebook.com/dancooldan',
  'https://github.com/danielghandahari',
  'https://www.linkedin.com/in/daniel-ghandahari-78b38a127/',
  'mailto: dangha94@gmail.com',
]

const Header = () => (
  <>
    <div className={s.wrapper}>
      <StaticQuery
        query={files}
        render={data => (
          <div className={s.imgContainer}>
            {data.allFile.edges.map((e, i) => (
              <a key={i} href={smLinks[i]} className="socialMediaIcon">
                <img className={s.imgStyle} src={e.node.publicURL} alt="" />
              </a>
            ))}
          </div>
        )}
      />
    </div>
  </>
)

export default Header

const files = graphql`
  query {
    allFile(filter: { publicURL: { regex: "/sm-icon/" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
